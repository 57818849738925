import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { LuCopy } from "react-icons/lu";

import api from "../../../services/api";
import Modal from "../../../components/Modal";

const DOMAINS = {
  market_size_coef: "Market size",
  market_share_coef: "Market share",
  competitive_advantage_coef: "Competitive advantage",
  disruptive_tech_coef: "Disruptive technology",
  key_access_coef: "Key access",
  growth_strategy_coef: "Growth strategy",
  team_skills_coef: "Team skills",
  product_validation_coef: "Product validation",
  tech_doability_coef: "Tech doability",
  tech_law_protection_coef: "Tech law protection",
  financial_strength_coef: "Financial strength",
  strategy_backing_coef: "Strategy backing",
  potential_impact_coef: "Potential impact",
  social_responsability_coef: "Social responsability",
};

const OPERATORS = {
  "==": "Égal",
  "!=": "Différent",
  ">": "Supérieur",
  "<": "Inférieur",
  "<=": "Inférieur ou égal",
  ">=": "Supérieur ou égal",
};

const EditModal = ({ isOpen, onClose, notation, setNotation }) => {
  const [questions, setQuestions] = useState([]);
  const [values, setValues] = useState(notation);

  useEffect(() => {
    if (!notation) return;
    const fetchData = async () => {
      try {
        const res = await api.get(`/notation-rules/${notation._id}`);
        if (res.ok) {
          setValues(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [notation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post("/question/search", { limit: 200 });
        if (res.ok) {
          res.data.sort((a, b) => a.question_fr.localeCompare(b.question_fr));
          setQuestions(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      const res = await api.put(`/notation-rules/${notation._id}`, values);
      if (res.ok) {
        setNotation(res.data);
        toast.success("Règle modifiée");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddAnswer = () => {
    setValues({
      ...values,
      answers: [...values.answers, { question_id: "", question_key: "", question: "", question_type: "", question_options: [], value: "", operator: "==", points: 0, actions: [] }],
    });
  };

  const handleAnswerChange = (i, v) => {
    setValues({ ...values, answers: values.answers.map((a, j) => (i === j ? { ...a, ...v } : a)) });
  };
  const handleDuplicateAnswer = (i) => {
    setValues({ ...values, answers: [...values.answers, { ...values.answers[i] }] });
  };

  const handleDeleteAnswer = (i) => {
    setValues({ ...values, answers: values.answers.filter((a, j) => i !== j) });
  };

  if (!values) return null;

  return (
    <Modal show={isOpen} onClose={onClose} className="w-[95%] md:w-[80%]">
      <div className="px-12 h-full overflow-auto">
        <h2 className="text-xl font-semibold mb-4">Modifier une règle de notation</h2>
        <div className="space-y-4">
          <div className="space-y-2">
            <h3 className="text-primary text-lg font-semibold">Nom du critère de notation</h3>
            <input placeholder="Nom" className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="space-y-2">
            <h3 className="text-primary text-lg font-semibold">Total points</h3>
            <input
              type="number"
              placeholder="Total points"
              className="input"
              value={values.total_points}
              onChange={(e) => setValues({ ...values, total_points: e.target.value })}
            />
          </div>
          <div className="space-y-2">
            <h3 className="text-primary text-lg font-semibold">Réponses</h3>
            {values.answers.map((a, i) => (
              <Answer
                key={i}
                answer={a}
                questions={questions}
                onChange={(v) => handleAnswerChange(i, v)}
                onDelete={() => handleDeleteAnswer(i)}
                onDuplicate={() => handleDuplicateAnswer(i)}
              />
            ))}

            <div className="flex justify-center !mt-8">
              <button className="primary-button" onClick={handleAddAnswer}>
                Ajouter une réponse
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button className="primary-button w-56" onClick={handleSubmit}>
            Modifier
          </button>
        </div>
      </div>
    </Modal>
  );
};

const Answer = ({ answer, onChange, onDelete, onDuplicate, questions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleQuestionChange = (id) => {
    const question = questions.find((q) => q._id === id);
    if (!question) return;
    onChange({ ...answer, question_id: id, question: question.question_fr, question_key: question.key, question_type: question.type, question_options: question.options });
  };

  const handleAddAction = () => {
    onChange({ ...answer, actions: [...answer.actions, { action: "points", key: "points", value: 0 }] });
  };

  const handleActionChange = (i, v) => {
    onChange({ ...answer, actions: answer.actions.map((a, j) => (i === j ? v : a)) });
  };

  const handleDeleteAction = (i) => {
    onChange({ ...answer, actions: answer.actions.filter((a, j) => i !== j) });
  };

  return (
    <div className="px-4">
      <div className="flex items-start justify-between gap-4">
        <button onClick={() => setIsOpen(!isOpen)} className="transparent-button px-1 text-black font-semibold w-full text-left justify-start">
          {answer.question} {OPERATORS[answer.operator].toLowerCase()} {answer.value}
          {isOpen ? " ▲" : " ▼"}
        </button>
        <div className="flex items-center gap-2">
          <button className="empty-button" onClick={onDuplicate}>
            <LuCopy className="mr-2" />
            Dupliquer
          </button>
          <button className="empty-button text-red-500 border-red-500" onClick={onDelete}>
            Supprimer
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="space-y-2 px-8 w-full">
          <h3 className="text-sm font-semibold">Question</h3>
          <div className="flex gap-4">
            <select className="select" value={answer.question_id} onChange={(e) => handleQuestionChange(e.target.value)}>
              <option value="">Choisissez une question</option>
              {questions.map((q, i) => (
                <option key={i} value={q._id}>
                  {q.question_fr}
                </option>
              ))}
            </select>
            <select className="select" value={answer.operator} onChange={(e) => onChange({ ...answer, operator: e.target.value })}>
              <option value="==">Égal</option>
              <option value="!=">Différent</option>
              <option value=">">Supérieur</option>
              <option value="<">Inférieur</option>
              <option value="<=">Inférieur ou égal</option>
              <option value=">=">Supérieur ou égal</option>
            </select>
            {answer.question_type === "multi-select" ? (
              <select className="select" value={answer.value} onChange={(e) => onChange({ ...answer, value: e.target.value })}>
                <option value="">Choisissez une option</option>
                {answer.question_options.map((o, i) => (
                  <option key={i} value={o.option_fr}>
                    {o.option_fr}
                  </option>
                ))}
              </select>
            ) : answer.question_type === "boolean" ? (
              <select className="select" value={answer.value} onChange={(e) => onChange({ ...answer, value: e.target.value })}>
                <option value="">Choisissez une option</option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            ) : (
              <input className="input" value={answer.value} onChange={(e) => onChange({ ...answer, value: e.target.value })} />
            )}
          </div>
          <h3 className="text-sm font-semibold">Action</h3>
          <div className="space-y-2">
            {answer.actions.map((action, i) => (
              <Action key={i} action={action} onChange={(v) => handleActionChange(i, v)} onDelete={() => handleDeleteAction(i)} />
            ))}

            <button className="empty-button" onClick={handleAddAction}>
              Ajouter une action
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Action = ({ action, onChange, onDelete }) => {
  return (
    <div className="flex gap-4 items-start w-full">
      <select className="select flex-1" value={action.action} onChange={(e) => onChange({ ...action, action: e.target.value })}>
        <option value="points">Changer le score</option>
        <option value="coefs">Changer les coéficients</option>
        <option value="exit">Ne pas évaluer</option>
      </select>
      {action.action === "points" ? (
        <input className="input flex-1" value={action.value} onChange={(e) => onChange({ ...action, value: e.target.value })} />
      ) : action.action === "coefs" ? (
        <div className="flex-1 flex gap-2">
          <select className="select flex-1" value={action.key} onChange={(e) => onChange({ ...action, key: e.target.value })}>
            {Object.entries(DOMAINS).map(([key, value], index) => (
              <option key={index} value={key}>
                {value}
              </option>
            ))}
          </select>
          <input type="number" className="input flex-1 p-1" value={action.value || 0} onChange={(e) => onChange({ ...action, value: e.target.value })} />
        </div>
      ) : null}
      <button className="empty-button text-red-500 border-red-500" onClick={onDelete}>
        Supprimer
      </button>
    </div>
  );
};

export default EditModal;
