import React from "react";
import { RiCloseLine } from "react-icons/ri";

const Modal = ({ show, children, onClose, className = "w-[95%] md:w-2/3" }) => {
  if (!show) return null;

  return (
    <div className="fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center">
      <div className="absolute inset-0 z-30 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm" onClick={onClose} />

      <div className={`absolute left-1/2 top-1/2 z-40 -translate-x-1/2 -translate-y-1/2 transform max-h-[95%] overflow-y-auto rounded-lg bg-white p-6 ${className}`}>
        <div className="absolute top-4 right-4">
          <button onClick={onClose} className="absolute top-3 right-3 w-6 h-6 cursor-pointer">
            <RiCloseLine onClick={onClose} className="w-6 h-6" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};
export default Modal;
