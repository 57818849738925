import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";
import Loader from "../../components/Loader";

const Home = () => {
  const [filters, setFilters] = useState({
    status: "buyer_nda",
    page: 1,
    pageSize: 50,
  });

  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/discussion/search`);
      if (!res.ok) return;
      setDiscussions(res.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-[80vh] flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-8">
      {/* 
      <div className="flex justify-between items-center mt-8">
        <div className="flex gap-4">
          <select className="select" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
            <option value="">Toutes les statuts</option>
            <option value="buyer_nda">NDA a signer par l'acheteur</option>
            <option value="seller_nda">NDA a signer par le vendeur</option>
            <option value="ongoing">Discussion en cours</option>
            <option value="stopped">Discussion arrêtée</option>
          </select>
        </div>
      </div> 
     */}

      <section className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center mb-4 px-4">
          <h3 className="w-1/6 text-sm font-semibold">Demandeur</h3>
          <h3 className="w-1/6 text-sm font-semibold">Personne contacté</h3>
          <h3 className="w-1/6 text-sm font-semibold">Date de création</h3>
          <h3 className="w-24 text-sm font-semibold">Statut</h3>
        </div>
        <div className="space-y-2">
          {discussions.map((e, index) => (
            <Link to={`/discussion/${e._id}`} key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-4">
              <div className="w-1/6">
                {e.user_from_first_name} {e.user_from_last_name}
              </div>
              <div className="w-1/6">
                {e.user_to_first_name} {e.user_to_last_name}
              </div>
              <div className="w-1/6">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
              <div className="w-24">
                <span className={`text-xs rounded-md text-center py-1 px-3 ${e.status == "PENDING" ? "bg-yellow-300" : e.status == "ONGOING" ? "bg-green-300" : "bg-red-300"}`}>
                  {e.status}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
