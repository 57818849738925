import { METABASE_TOKEN } from "../../services/config";

const Home = () => {
  return (
    <div className="p-8 w-full h-full flex flex-col gap=">
      <h1 className="text-2xl font-bold">Tableau de bord</h1>
      <div className="flex-1">
        <iframe src={`https://selego-metabase.cleverapps.io/embed/dashboard/${METABASE_TOKEN}#bordered=true&titled=true`} width="100%" height="100%" allowtransparency></iframe>
      </div>
    </div>
  );
};

export default Home;
