import React, { useState } from "react";
import toast from "react-hot-toast";

import { PDF_URL } from "../../../services/config";
import Switch from "../../../components/Switch";
import api from "../../../services/api";

const Report = ({ company, setCompany }) => {
  const [editing, setEditing] = useState(false);
  const [textProblem, setTextProblem] = useState(company.pdf_problem);
  const [textSolution, setTextSolution] = useState(company.pdf_solution);
  const [textValueProposition, setTextValueProposition] = useState(company.pdf_value_proposition);
  const [textPitch, setTextPitch] = useState(company.elevator_pitch);
  const [textWebsite, setTextWebsite] = useState(company.website);
  const [textEmail, setTextEmail] = useState(company.email);
  const [textPhone, setTextPhone] = useState(company.phone_number);
  const [timeline, setTimeline] = useState(company.pdf_timeline);

  const MATURITY_STAGE = ["Pre-seed", "Seed", "Early Growth", "Fast Growth", "Mature Stable"];
  const CONCEPT_STAGE = ["Recherche", "Tests", "Recherche de PMF", "PMF"];
  const PRODUCT_MATURITY = ["Idéation", "POC", "Prototype", "MVP", "Produit final"];

  const handlePublish = async () => {
    try {
      const res = await api.put(`/company/${company._id}`, {
        pdf_problem: textProblem,
        pdf_solution: textSolution,
        pdf_value_proposition: textValueProposition,
        pdf_timeline: timeline,
        elevator_pitch: textPitch,
        company_website: textWebsite,
        email: textEmail,
        phone_number: textPhone,
      });
      if (!res.ok) throw res;
      toast.success("Les informations ont été mises à jour");
      setCompany(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la mise à jour des informations");
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center gap-5 p-3 mt-2">
        <h1 className="text-xl font-semibold">Prévisualisation</h1>
        <Switch checked={editing} onChange={(v) => setEditing(v)} />
        <h1 className="text-xl font-semibold">Édition</h1>
        {editing && (
          <button className="primary-button w-fit" onClick={() => handlePublish()}>
            Enregistrer
          </button>
        )}
      </div>
      {editing ? (
        <div className="flex flex-between w-full p-2 space-x-3 rounded-t-lg">
          <div className="flex flex-col gap-5 w-[55%] my-2">
            <h1 className="text-2xl font-semibold mt-1 mb-3 text-red-500">Rapport de valorisation</h1>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Problème abordé</label>
              <textarea type="text" className="input" value={textProblem} onChange={(e) => setTextProblem(e.target.value)} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Solution développée</label>
              <textarea type="text" className="input" value={textSolution} onChange={(e) => setTextSolution(e.target.value)} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Proposition de valeur</label>
              <textarea type="text" className="input" value={textValueProposition} onChange={(e) => setTextValueProposition(e.target.value)} />
            </div>

            <div className="flex flex-col justify-center gap-2 mt-5">
              <label className="text-red-500 text-xl">Attention ! Les 3 champs suivant risquent d'affecter la notation et la valorisation.</label>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Stade de maturité de la startup</label>
              <select
                className="select w-fit"
                value={company.maturity_stage}
                onChange={(e) => {
                  setCompany({ ...company, maturity_stage: e.target.value });
                }}>
                {MATURITY_STAGE.map((stage) => (
                  <option key={stage} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Stade de maturité du concept</label>
              <select
                className="select w-fit"
                value={company.stage_of_concept}
                onChange={(e) => {
                  setCompany({ ...company, stage_of_concept: e.target.value });
                }}>
                {CONCEPT_STAGE.map((stage) => (
                  <option key={stage} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Stade de maturité du produit</label>
              <select
                className="select w-fit"
                value={company.product_stage}
                onChange={(e) => {
                  setCompany({ ...company, product_stage: e.target.value });
                }}>
                {PRODUCT_MATURITY.map((stage) => (
                  <option key={stage} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-red-500 text-xl">----------------------------------------</label>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Date prévue de la levée de fond</label>
              <input
                type="text"
                className="input"
                value={company.pdf_fundraising_date}
                onChange={(e) => {
                  setCompany({ ...company, pdf_fundraising_date: e.target.value });
                }}
              />
            </div>
            <div className="flex justify-between items-center">
              <label className="text-black text-xl">Timeline</label>
              <button className="primary-button" onClick={() => setTimeline([...timeline, { text: null, side: "left" }])}>
                Ajouter
              </button>
            </div>
            <div className="flex flex-col items-start gap-8">
              {timeline.map((item, index) => (
                <div key={index} className="flex w-full flex-col gap-1">
                  <div className="flex justify-between items-center gap-3">
                    <input
                      type="text"
                      className="input"
                      value={item.title}
                      onChange={(e) => {
                        const newTimeline = [...timeline];
                        newTimeline[index].title = e.target.value;
                        setTimeline(newTimeline);
                      }}
                    />

                    <select
                      className="select w-fit"
                      value={item.side}
                      onChange={(e) => {
                        const newTimeline = [...timeline];
                        newTimeline[index].side = e.target.value;
                        setTimeline(newTimeline);
                      }}>
                      <option value="left">{"<--"}</option>
                      <option value="right">{"-->"}</option>
                    </select>
                    <select
                      className="select w-fit"
                      value={item.time}
                      onChange={(e) => {
                        const newTimeline = [...timeline];
                        newTimeline[index].time = e.target.value;
                        setTimeline(newTimeline);
                      }}>
                      <option value="past">Passé</option>
                      <option value="future">Prévisionnel</option>
                    </select>
                    <button className="empty-button" onClick={() => setTimeline(timeline.filter((_, i) => i !== index))}>
                      Supprimer
                    </button>
                  </div>
                  <textarea
                    type="text"
                    className="input"
                    value={item.text}
                    onChange={(e) => {
                      const newTimeline = [...timeline];
                      newTimeline[index].text = e.target.value;
                      setTimeline(newTimeline);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="h-full bg-primary w-1" />

          <div className="flex flex-col gap-5 w-[45%] m-2">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-2xl font-semibold mt-1 mb-3 text-red-500">Certificat de notation</h1>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Site Internet</label>
                <input type="text" className="input" value={textWebsite} onChange={(e) => setTextWebsite(e.target.value)} />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Email</label>
                <input type="text" className="input" value={textEmail} onChange={(e) => setTextEmail(e.target.value)} />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Téléphone</label>
                <input type="text" className="input" value={textPhone} onChange={(e) => setTextPhone(e.target.value)} />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <label className="text-black text-xl">Elevator Pitch</label>
                <textarea type="text" className="input" value={textPitch} onChange={(e) => setTextPitch(e.target.value)} maxLength={600} />
                {<p className="text-gray-500 text-sm">{textPitch?.length || "0"}/600 charactères</p>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Preview company={company} />
      )}
    </div>
  );
};

const Preview = ({ company }) => {
  return (
    <div className="w-full overflow-visible flex justify-center">
      <iframe src={`${PDF_URL}/start-up/${company._id}`} className="border-2 border-black w-[1400px] h-[1684px] scale-75 origin-top-left p-0 m-0" />
    </div>
  );
};

export default Report;
