import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import ImageUploadSVG from "../../assets/svg/upload-image.svg";
import { MdDeleteOutline } from "react-icons/md";

import api from "../../services/api";
import Switch from "../../components/Switch";

const STATUS = {
  DRAFT: <span className="text-xs text-gray-500">Brouillon</span>,
  PENDING: <span className="text-xs text-yellow-500">En attente</span>,
  ACCEPTED: <span className="text-xs text-green-500">Validée</span>,
  REJECTED: <span className="text-xs text-red-500">Refusée</span>,
};

const getDepartement = (code) => code && code in DEPARTMENT_NAMES && DEPARTMENT_NAMES[code][0];
const getRegion = (code) => code && code in DEPARTMENT_NAMES && DEPARTMENT_NAMES[code][1];

const getDepartmentCode = (postalCode) => {
  if (!postalCode) return null;
  let code;
  if (postalCode.length === 5) code = postalCode.slice(0, 2);
  else if (postalCode.length === 4) code = postalCode.slice(0, 1);
  else return null;
  if (code === "97" || code === "98") code = postalCode.slice(0, 3);
  if (DEPARTMENT_NAMES.hasOwnProperty(code)) return code;
  else return null;
};
const View = () => {
  const { id } = useParams();

  const [company, setCompany] = useState();
  const [values, setValues] = useState({});
  const [user, setUser] = useState();
  const [view, setView] = useState("infos");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/company/${id}`);
        if (res.ok) {
          setCompany(res.data);
          setValues(res.data);

          const userRes = await api.get(`/user/${res.data.user_id}`);
          if (userRes.ok) setUser(userRes.data);
          else toast.error("Une erreur est survenue");
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleChange = async (values) => {
    try {
      const res = await api.put(`/company/${id}`, values);
      if (res.ok) {
        toast.success("La levée de fonds a bien été modifiée");
        setCompany(res.data);
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };
  const hasChanged = (a, b) => {
    if (a.annonce_title !== b.annonce_title) return true;
    if (a.annonce_description !== b.annonce_description) return true;
    if (a.annonce_image !== b.annonce_image) return true;
    return false;
  };
  if (!company || !user) return <></>;

  return (
    <div className="flex flex-col p-8">
      <header className="w-full mb-8">
        <h1 className="text-3xl font-semibold text-black mb-2">Cession</h1>
      </header>

      <div className="flex justify-between mb-4">
        <div className="flex gap-5 mb-5">
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "infos" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("infos")}>
            Informations
          </button>
          <button type="button" className={`rounded-lg px-4 py-2 ${view === "raw" ? "bg-gray-100" : "border border-secondary"}`} onClick={() => setView("raw")}>
            Données brutes
          </button>
        </div>
        <div className="flex items-center gap-4">
          {hasChanged(values, company) && (
            <>
              <button type="button" className="empty-button" onClick={() => setValues(company)}>
                Annuler
              </button>
              <button type="button" className="primary-button" onClick={() => handleChange(values)}>
                Enregistrer
              </button>
            </>
          )}

          {company.deleted_at ? (
            <button type="button" className="primary-button" onClick={() => handleChange({ deleted_at: null })}>
              Restaurer
            </button>
          ) : (
            <button type="button" className="red-button" onClick={() => handleChange({ deleted_at: new Date() })}>
              Supprimer
            </button>
          )}

          <select className="select" value={company.annonce_status} onChange={(e) => handleChange({ annonce_status: e.target.value })}>
            <option value="DRAFT">Brouillon</option>
            <option value="PENDING">En attente</option>
            <option value="ACCEPTED">Acceptée</option>
            <option value="REJECTED">Refusée</option>
          </select>
        </div>
      </div>

      {view === "infos" ? (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 border flex items-center border-secondary rounded-lg p-5 gap-4">
            <h1 className="text-xl font-medium text-primary">Afficher l'entreprise de manière anonyme sur la plateforme</h1>
            <Switch checked={company.annonce_confidential} onChange={(v) => handleChange({ annonce_confidential: v })} />
          </div>

          <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Créée par</h1>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-primary text-sm font-medium">
                  Prénom: <span className="text-sm text-black">{user.first_name}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Nom: <span className="text-sm text-black">{user.last_name}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  E-mail: <span className="text-sm text-black">{user.email}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Numéro: <span className="text-sm text-black">{user.phone}</span>
                </p>
              </div>
              <div>
                <p className="text-primary text-sm font-medium">
                  Créée le: <span className="text-sm text-black">{company.created_at?.toLocaleString("fr-FR")}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Dernière modification le: <span className="text-sm text-black">{company.updated_at?.toLocaleString("fr-FR")}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Statut: <span className="text-sm text-black">{STATUS[company.annonce_status]}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Titre</h1>
            <input
              type="text"
              name="title"
              value={values.annonce_title}
              onChange={(e) => setValues({ ...values, annonce_title: e.target.value })}
              className="w-full rounded-lg border px-3 py-2"
            />
            <h1 className="text-xl font-medium text-primary">Description</h1>
            <textarea
              rows={10}
              type="text"
              name="description"
              value={values.annonce_description || ""}
              onChange={(e) => setValues({ ...values, annonce_description: e.target.value })}
              className="w-full rounded-lg border px-3 py-2"
            />
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Informations générales</h1>
            <p className="text-primary text-sm font-medium">
              Type d'opération: <span className="text-sm text-black">Cession</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Type de d'entreprise: <span className="text-sm text-black">{company.company_category}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Secteur d'activité: <span className="text-sm text-black">{company.company_activity}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Site web: <span className="text-sm text-black">{company.company_website}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              LinkedIn: <span className="text-sm text-black">{company.company_linkedin}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Numéro de Siren: <span className="text-sm text-black">{company.company_siren}</span>
            </p>
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Localisation et Confidentialité</h1>
            <p className="text-primary text-sm font-medium">
              Pays de domicilation: <span className="text-sm text-black">{company.country}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Ville: <span className="text-sm text-black">{company.city}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Code Postal: <span className="text-sm text-black">{company.postal_code}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Département: <span className="text-sm text-black">{getDepartement(getDepartmentCode(company.postal_code))}</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Region: <span className="text-sm text-black">{getRegion(getDepartmentCode(company.postal_code))}</span>
            </p>
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Informations Financières</h1>
            <p className="text-primary text-sm font-medium">Chiffre d'affaire annuel</p>
            <p className="text-primary text-sm font-medium">
              2022: <span className="text-sm text-black">{company.turnover_2}€</span>
            </p>
            <p className="text-primary text-sm font-medium">
              2023: <span className="text-sm text-black">{company.turnover_3}€</span>
            </p>
            <p className="text-primary text-sm font-medium">
              Estimation 2024: <span className="text-sm text-black">{company.turnover_4}€</span>
            </p>
          </div>
          <div className="border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Prix de vente souhaité</h1>
            <div className="flex justify-center items-center h-full">
              <p className="text-primary text-2xl font-semibold">
                {company.annonce_selling_price ? company.annonce_selling_price.toLocaleString("fr-FR", { style: "currency", currency: "EUR" }) : "Non renseigné"}
              </p>
            </div>
          </div>
          <div className="col-span-2 border border-secondary rounded-lg p-5 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-primary">Image</h1>
            <div className="flex flex-wrap  w-1/3 gap-2">
              <ImageInput name="image" value={values.annonce_image} onChange={(e) => setValues({ ...values, annonce_image: e.target.value })} />
              {/* {values.images && values.images.length > 0 ? values.images.map((image) => <ImageViewer alt={values.title} src={image} />) : null} */}
            </div>
          </div>
        </div>
      ) : (
        <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
          <pre className="text-white text-sm">{JSON.stringify(company, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

const DEPARTMENT_NAMES = {
  "01": ["Ain", "Auvergne-Rhône-Alpes"],
  "02": ["Aisne", "Hauts-de-France"],
  "03": ["Allier", "Auvergne-Rhône-Alpes"],
  "04": ["Alpes-de-Haute-Provence", "Provence-Alpes-Côte d'Azur"],
  "05": ["Hautes-Alpes", "Provence-Alpes-Côte d'Azur"],
  "06": ["Alpes-Maritimes", "Provence-Alpes-Côte d'Azur"],
  "07": ["Ardèche", "Auvergne-Rhône-Alpes"],
  "08": ["Ardennes", "Grand Est"],
  "09": ["Ariège", "Occitanie"],
  10: ["Aube", "Grand Est"],
  11: ["Aude", "Occitanie"],
  12: ["Aveyron", "Occitanie"],
  13: ["Bouches-du-Rhône", "Provence-Alpes-Côte d'Azur"],
  14: ["Calvados", "Normandie"],
  15: ["Cantal", "Auvergne-Rhône-Alpes"],
  16: ["Charente", "Nouvelle-Aquitaine"],
  17: ["Charente-Maritime", "Nouvelle-Aquitaine"],
  18: ["Cher", "Centre-Val de Loire"],
  19: ["Corrèze", "Nouvelle-Aquitaine"],
  "2A": ["Corse-du-Sud", "Corse"],
  "2B": ["Haute-Corse", "Corse"],
  21: ["Côte-d'Or", "Bourgogne-Franche-Comté"],
  22: ["Côtesd'Armor", "Bretagne"],
  23: ["Creuse", "Nouvelle-Aquitaine"],
  24: ["Dordogne", "Nouvelle-Aquitaine"],
  25: ["Doubs", "Bourgogne-Franche-Comté"],
  26: ["Drôme", "Auvergne-Rhône-Alpes"],
  27: ["Eure", "Normandie"],
  28: ["Eure-et-Loir", "Centre-Val de Loire"],
  29: ["Finistère", "Bretagne"],
  30: ["Gard", "Occitanie"],
  31: ["Haute-Garonne", "Occitanie"],
  32: ["Gers", "Occitanie"],
  33: ["Gironde", "Nouvelle-Aquitaine"],
  34: ["Hérault", "Occitanie"],
  35: ["Ille-et-Vilaine", "Bretagne"],
  36: ["Indre", "Centre-Val de Loire"],
  37: ["Indre-et-Loire", "Centre-Val de Loire"],
  38: ["Isère", "Auvergne-Rhône-Alpes"],
  39: ["Jura", "Bourgogne-Franche-Comté"],
  40: ["Landes", "Nouvelle-Aquitaine"],
  41: ["Loir-et-Cher", "Centre-Val de Loire"],
  42: ["Loire", "Auvergne-Rhône-Alpes"],
  43: ["Haute-Loire", "Auvergne-Rhône-Alpes"],
  44: ["Loire-Atlantique", "Pays de la Loire"],
  45: ["Loiret", "Centre-Val de Loire"],
  46: ["Lot", "Occitanie"],
  47: ["Lot-et-Garonne", "Nouvelle-Aquitaine"],
  48: ["Lozère", "Occitanie"],
  49: ["Maine-et-Loire", "Pays de la Loire"],
  50: ["Manche", "Normandie"],
  51: ["Marne", "Grand Est"],
  52: ["Haute-Marne", "Grand Est"],
  53: ["Mayenne", "Pays de la Loire"],
  54: ["Meurthe-et-Moselle", "Grand Est"],
  55: ["Meuse", "Grand Est"],
  56: ["Morbihan", "Bretagne"],
  57: ["Moselle", "Grand Est"],
  58: ["Nièvre", "Bourgogne-Franche-Comté"],
  59: ["Nord", "Hauts-de-France"],
  60: ["Oise", "Hauts-de-France"],
  61: ["Orne", "Normandie"],
  62: ["Pas-de-Calais", "Hauts-de-France"],
  63: ["Puy-de-Dôme", "Auvergne-Rhône-Alpes"],
  64: ["Pyrénées-Atlantiques", "Nouvelle-Aquitaine"],
  65: ["Hautes-Pyrénées", "Occitanie"],
  66: ["Pyrénées-Orientales", "Occitanie"],
  67: ["Bas-Rhin", "Grand Est"],
  68: ["Haut-Rhin", "Grand Est"],
  69: ["Rhône", "Auvergne-Rhône-Alpes"],
  70: ["Haute-Saône", "Bourgogne-Franche-Comté"],
  71: ["Saône-et-Loire", "Bourgogne-Franche-Comté"],
  72: ["Sarthe", "Pays de la Loire"],
  73: ["Savoie", "Auvergne-Rhône-Alpes"],
  74: ["Haute-Savoie", "Auvergne-Rhône-Alpes"],
  75: ["Paris", "Île-de-France"],
  76: ["Seine-Maritime", "Normandie"],
  77: ["Seine-et-Marne", "Île-de-France"],
  78: ["Yvelines", "Île-de-France"],
  79: ["Deux-Sèvres", "Nouvelle-Aquitaine"],
  80: ["Somme", "Hauts-de-France"],
  81: ["Tarn", "Occitanie"],
  82: ["Tarn-et-Garonne", "Occitanie"],
  83: ["Var", "Provence-Alpes-Côte d'Azur"],
  84: ["Vaucluse", "Provence-Alpes-Côte d'Azur"],
  85: ["Vendée", "Pays de la Loire"],
  86: ["Vienne", "Nouvelle-Aquitaine"],
  87: ["Haute-Vienne", "Nouvelle-Aquitaine"],
  88: ["Vosges", "Grand Est"],
  89: ["Yonne", "Bourgogne-Franche-Comté"],
  90: ["TerritoiredeBelfort", "Bourgogne-Franche-Comté"],
  91: ["Essonne", "Île-de-France"],
  92: ["Hauts-de-Seine", "Île-de-France"],
  93: ["Seine-St-Denis", "Île-de-France"],
  94: ["Val-de-Marne", "Île-de-France"],
  95: ["Val-D'Oise", "Île-de-France"],
  971: ["Guadeloupe", "Guadeloupe"],
  972: ["Martinique", "Martinique"],
  973: ["Guyane", "Guyane"],
  974: ["La Réunion", "La Réunion"],
  976: ["Mayotte", "Mayotte"],
};

const ImageInput = ({ name, value, onChange, folder }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async () => {
    onChange({ target: { value: "", name } });
  };

  return (
    <>
      <label
        htmlFor={name}
        className={`bg-white mt-4 p-4 rounded-full w-full h-full ${
          value ? "hover:backdrop-blur-sm bg-white/10" : "hover:bg-gray-50"
        } hover:bg-gray-50 transition-all cursor-pointer flex flex-col justify-center items-center border border-secondary border-dashed`}>
        {loading ? (
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
          </div>
        ) : value ? (
          <div className="relative">
            <img src={value} className="w-full h-full object-cover" />
            <button className="absolute -top-4 -right-4 w-6 h-6 bg-secondary rounded-full flex items-center justify-center" onClick={handleDeleteImage}>
              <MdDeleteOutline size={16} />
            </button>
          </div>
        ) : (
          <img src={ImageUploadSVG} className="w-1/3 h-1/3" />
        )}
      </label>
      <input
        id={name}
        type="file"
        className="hidden"
        disabled={loading}
        onChange={async (e) => {
          setLoading(true);
          const formData = new FormData();
          formData.append("files", e.target.files[0]);
          formData.append("folder", "avatar");
          const { data } = await api.postFormData(`/file`, formData);
          onChange({ target: { value: data[0], name } });
          setLoading(false);
        }}
      />
    </>
  );
};

export default View;
