const Infos = ({ company }) => {
  return (
    <div>
      <h1 className="text-xl font-semibold mb-8">Informations</h1>

      <div className="space-y-4">
        <div className="border border-secondary p-3 rounded-lg grid grid-cols-2 gap-2 text-lg">
          <div className="col-span-2">
            <h3 className="font-semibold text-xl">Entreprise</h3>
          </div>
          <label className="text-right">Nom de l'entreprise</label>
          <p className="font-semibold">{company.company_name}</p>
          <label className="text-right">SIREN</label>
          <p className="font-semibold">{company.company_siren}</p>
          <label className="text-right">Taille d'entreprise</label>
          <p className="font-semibold">{company.company_category}</p>
          <label className="text-right">Secteur d'activité</label>
          <p className="font-semibold">{company.company_activity}</p>
          <label className="text-right">Date de création</label>
          <p className="font-semibold">{company.company_started_at ? new Date(company.company_started_at).toLocaleDateString("fr-FR") : "-"}</p>
        </div>

        <div className="border border-secondary p-3 rounded-lg grid grid-cols-2 gap-2 text-lg">
          <div className="col-span-2">
            <h3 className="font-semibold text-xl">Valorisation</h3>
          </div>
          <label className="text-right">Valorisation finale</label>
          <p className="font-semibold">{Math.round(company.valuation_global || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
        </div>
      </div>
    </div>
  );
};

export default Infos;
