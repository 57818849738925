import React from "react";

const STARTING_YEAR = 2020;

const NetTotal = ({ values }) => {
  const KEYS = ["net_income", "dna", "depreciations", "investments", "provisions_fixed_assets"];
  const KEYS_LABEL = {
    net_income: "Revenu net",
    dna: "Amortissement et dépréciation (D&A)",
    depreciations: "Dépréciations",
    investments: "Investissements",
    provisions_fixed_assets: "Provisions pour actifs immobilisés",
  };

  const years = Array.from(Array(11).keys()).map((i) => STARTING_YEAR + i);

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg border-gray-300 table-fixed">
        <thead>
          <tr className="">
            <th colSpan="1" className="border text-gray-700 font-normal text-sm"></th>
            <th colSpan="5" className="border p-2  text-left text-gray-700 font-normal text-sm">
              {"Bilan passé"}
            </th>
            <th colSpan="6" className="border p-2 text-left text-gray-700 font-normal text-sm">
              {"Bilan prévisionnel"}
            </th>
          </tr>
          <tr className="text-sm">
            <th className="border p-2">{"Année"}</th>
            {years.map((year) => (
              <th key={year} className="border p-2">
                {year}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {KEYS.map((key) => (
            <tr key={key}>
              <td className="border p-2 text-xs w-56">{KEYS_LABEL[key]}</td>
              {years.map((year, index) => (
                <td key={year} className={`border text-xs px-2`}>
                  {values[`${key}_${index}`]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NetTotal;
