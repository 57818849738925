import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Modal from "../../../components/Modal";

const DOMAINS = {
  market_size_coef: "Market size",
  market_share_coef: "Market share",
  competitive_advantage_coef: "Competitive advantage",
  disruptive_tech_coef: "Disruptive technology",
  key_access_coef: "Key access",
  growth_strategy_coef: "Growth strategy",
  team_skills_coef: "Team skills",
  product_validation_coef: "Product validation",
  tech_doability_coef: "Tech doability",
  tech_law_protection_coef: "Tech law protection",
  financial_strength_coef: "Financial strength",
  strategy_backing_coef: "Strategy backing",
  potential_impact_coef: "Potential impact",
  social_responsability_coef: "Social responsability",
};

const CreateModal = ({ total, setData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    answers: [],
    total_points: 10,
    name: `Critère ${total + 1}`,
  });

  const handleSubmit = async () => {
    try {
      const res = await api.post("/notation-rules", values);
      if (res.ok) {
        setData((prev) => [...prev, res.data]);
        setIsOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="primary-button">
        Ajouter une règle
      </button>
      <Modal show={isOpen} onClose={() => setIsOpen(false)}>
        <h2 className="text-xl font-semibold mb-4">Ajouter une règle</h2>
        <div className="space-y-4">
          <div className="space-y-2">
            <h3 className="text-primary text-lg font-semibold">Nom du critère de notation</h3>
            <input placeholder="Nom" className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="space-y-2">
            <h3 className="text-primary text-lg font-semibold">Total points</h3>
            <input
              type="number"
              placeholder="Total points"
              className="input"
              value={values.total_points}
              onChange={(e) => setValues({ ...values, total_points: e.target.value })}
            />
          </div>

          <div className="space-y-2">
            <h3 className="text-primary text-lg font-semibold">Coefficients par défault</h3>

            <div className="flex flex-col gap-4 p-4">
              <div className="flex gap-4">
                <label className="flex-1 font-semibold">Domaine</label>
                <label className="flex-1 font-semibold text-right">Coefficient</label>
              </div>
              {Object.entries(DOMAINS).map(([key, value], index) => (
                <div key={index} className="flex gap-4">
                  <label className="flex-1">{value}</label>
                  <div className="w-1/3">
                    <input className="input py-1" value={values[key]} onChange={(e) => setValues({ ...values, [key]: e.target.value })} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button className="primary-button w-56" onClick={handleSubmit}>
            Ajouter
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateModal;
