import React from "react";
import { Route, Routes } from "react-router-dom";

import List from "./List";
import SME from "./sme";
// import SME from "./SMEView";
import StartUp from "./start-up";

const Valuation = () => (
  <Routes>
    <Route path="/" element={<List />} />
    <Route path="/sme/:id/*" element={<SME />} />
    <Route path="/start-up/:id/*" element={<StartUp />} />
  </Routes>
);

export default Valuation;
