import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import api from "../../services/api";
import CreateModal from "./components/CreateModal";
import EditModal from "./components/EditModal";

const DOMAINS = {
  market_size_coef: "Market size",
  market_share_coef: "Market share",
  competitive_advantage_coef: "Competitive advantage",
  disruptive_tech_coef: "Disruptive technology",
  key_access_coef: "Key access",
  growth_strategy_coef: "Growth strategy",
  team_skills_coef: "Team skills",
  product_validation_coef: "Product validation",
  tech_doability_coef: "Tech doability",
  tech_law_protection_coef: "Tech law protection",
  financial_strength_coef: "Financial strength",
  strategy_backing_coef: "Strategy backing",
  potential_impact_coef: "Potential impact",
  social_responsability_coef: "Social responsability",
};

const DOMAINS_COLORS = [
  "bg-red-200",
  "bg-blue-200",
  "bg-green-200",
  "bg-yellow-200",
  "bg-purple-200",
  "bg-pink-200",
  "bg-indigo-200",
  "bg-gray-200",
  "bg-red-200",
  "bg-blue-200",
  "bg-green-200",
  "bg-yellow-200",
  "bg-purple-200",
  "bg-pink-200",
  "bg-indigo-200",
  "bg-gray-200",
];

const Notation = () => {
  const [notationRules, setNotationRules] = useState([]);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (notationRules.length && searchParams.has("rule")) {
      const rule = notationRules.find((e) => e._id === searchParams.get("rule"));
      if (rule) setSelected(rule);
    }
  }, [notationRules, searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post("/notation-rules/search", {limit:300});
        if (res.ok) {
          setNotationRules(res.data);
          setTotal(res.total);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const handleOpenRule = (rule) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("rule", rule._id);
    setSearchParams(newSearchParams);
    setSelected(rule);
  };
  const handleCloseRule = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("rule");
    setSearchParams(newSearchParams);
    setSelected(null);
  };

  const handleChangeRule = (rule) => {
    const newRules = notationRules.map((e) => (e._id === rule._id ? rule : e));
    setNotationRules(newRules);
  };

  return (
    <div className="flex flex-col p-8">
      <EditModal isOpen={!!selected} onClose={handleCloseRule} rules={notationRules} notation={selected} setNotation={handleChangeRule} />
      <header className="w-full mb-8 flex justify-between">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold text-black mb-2 mr-4">Règles de notation</h1>
        </div>
        <CreateModal total={total} setData={setNotationRules} />
      </header>
      <section className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center mb-4 pl-4">
          <h3 className="w-[20%] text-sm font-semibold">Nom</h3>
          <h3 className="flex-1 text-sm font-semibold">Questions</h3>
          <h3 className="w-[15%] text-sm font-semibold">Total de points</h3>
          <h3 className="w-[15%] text-sm font-semibold">Dernière modification</h3>
        </div>

        <div className="space-y-2">
          {notationRules.map((e, i) => (
            <div key={i} className="flex justify-between items-center gap-3">
              <button
                onClick={() => handleOpenRule(e)}
                className="flex-1 border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-2">
                <div className="w-[20%] text-left pr-4">{e.name}</div>
                <div className="flex-1 line-clamp-2 text-left">{e.answers.map((q, j) => q.question).join(", ")}</div>
                <div className="w-[15%]">{e.total_points}</div>
                <div className="w-[15%]">{new Date(e.updated_at).toLocaleDateString("fr-FR")}</div>
              </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Notation;
