import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";

import api from "../../services/api";
import Modal from "../../components/Modal";
import DebounceInput from "../../components/DebounceInput";

const CATEGORIES = [
  { key: "human", label: "Humain" },
  { key: "offer", label: "Offre" },
  { key: "market", label: "Marché" },
  { key: "strategy", label: "Stratégie" },
  { key: "innovation", label: "Innovation" },
  { key: "finance", label: "Finance" },
  { key: "impact", label: "Impact" },
];

const SUB_CATEGORIES = {
  human: [
    { key: "team", label: "Équipe" },
    { key: "support", label: "Accompagnement" },
  ],
  offer: [
    { key: "product", label: "Produit" },
    { key: "competition", label: "Concurrence" },
    { key: "commercialization", label: "Commercialisation" },
    { key: "monetization", label: "Monétisation" },
  ],
  market: [
    { key: "position", label: "Positionnement" },
    { key: "characteristics", label: "Caractéristiques" },
    { key: "competitors", label: "Concurrents" },
  ],
  strategy: [
    { key: "partnership", label: "Partenariats" },
    { key: "ip", label: "IP" },
    { key: "communication", label: "Communication" },
    { key: "growth", label: "Croissance" },
  ],
  innovation: [],
  finance: [],
  impact: [],
};

const Question = () => {
  const [questions, setQuestions] = useState([]);
  const [filters, setFilters] = useState({
    limit: 300,
    search: "",
  });
  const [selected, setSelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (questions.length && searchParams.has("question")) {
      const rule = questions.find((e) => e._id === searchParams.get("question"));
      if (rule) setSelected(rule);
    }
  }, [questions, searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post("/question/search", filters);
        if (res.ok) {
          setQuestions(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [filters]);

  const handleOpenQuestion = (rule) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("question", rule._id);
    setSearchParams(newSearchParams);
    setSelected(rule);
  };
  const handleCloseQuestion = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("question");
    setSearchParams(newSearchParams);
    setSelected(null);
  };

  const handleChangeQuestion = (rule) => {
    const newValues = questions.map((e) => (e._id === rule._id ? rule : e));
    setQuestions(newValues);
  };

  return (
    <div className="flex flex-col p-8">
      <EditModal isOpen={!!selected} onClose={handleCloseQuestion} data={selected} setData={handleChangeQuestion} />
      <header className="w-full mb-8 flex justify-between">
        <div className="flex items-center">
          <h1 className="text-3xl font-semibold text-black mb-2 mr-4">Question des formulaire</h1>
        </div>
        <CreateModal setData={setQuestions} />
      </header>
      <section className="flex items-center mb-8">
        <div className="space-y-2 flex-1">
          <label className="flex-1 font-semibold">Question (fr)</label>
          <DebounceInput className="input" onChange={(value) => setFilters({ ...filters, search: value })} value={filters.search} debounce={300} />
        </div>
      </section>
      <section className="w-full flex flex-col">
        <div className="flex justify-between items-center mb-4 pl-4 text-xs font-semibold">
          <h3 className="flex-1">Question</h3>
          <h3 className="w-[20%]">Clé</h3>
          <h3 className="w-[20%]">Type de question</h3>
          <h3 className="w-[20%]">Valorisation</h3>
        </div>

        <div className="space-y-2">
          {questions.map((e, index) => (
            <div key={index} className="flex justify-between items-center gap-3">
              <button
                onClick={() => handleOpenQuestion(e)}
                className="flex-1 border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-2">
                <div className="flex-1 text-left">{e.question_fr || e.question_en}</div>
                <div className="w-[20%] text-left">{e.key}</div>
                <div className="w-[20%] text-left">{e.type}</div>
                <div className="w-[20%] text-left">
                  {e.startup && <span className="text-green-700">Startup</span>}
                  {e.sme && <span className="text-yellow-700">TPE / PME</span>}
                </div>
              </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

const CreateModal = ({ setData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    question_fr: "",
    question_en: "",
    key: "",
    type: "text",
    category: "",
    sub_category: "",
    startup: false,
    sme: false,
    options: [],
  });

  const handleSubmit = async () => {
    try {
      const res = await api.post("/question", values);
      if (res.ok) {
        setData((prev) => [...prev, res.data]);
        setIsOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddOption = () => {
    setValues({ ...values, options: [...values.options, { option_fr: "", option_en: "" }] });
  };
  const handleOptionChange = (index, key, value) => {
    setValues({ ...values, options: values.options.map((r, i) => (i === index ? { ...r, [key]: value } : r)) });
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="primary-button">
        Ajouter une règle
      </button>
      <Modal show={isOpen} onClose={() => setIsOpen(false)}>
        <h2 className="text-xl font-semibold mb-4">Ajouter une question</h2>

        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <label className="font-semibold text-sm">Question (fr)</label>
            <input type="text" placeholder="Question (fr)" className="input" value={values.question_fr} onChange={(e) => setValues({ ...values, question_fr: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label className="font-semibold text-sm">Question (en)</label>
            <input type="text" placeholder="Question (en)" className="input" value={values.question_en} onChange={(e) => setValues({ ...values, question_en: e.target.value })} />
          </div>
          <div className="flex items-center gap-4">
            <div className="flex-1  space-y-2">
              <label className="font-semibold text-sm">Catégorie</label>
              <select className="select" value={values.category} onChange={(e) => setValues({ ...values, category: e.target.value })}>
                <option value="">Aucune</option>
                {CATEGORIES.map((e, index) => (
                  <option key={index} value={e.key}>
                    {e.label}
                  </option>
                ))}
              </select>
            </div>
            {values.category && (
              <div className="flex-1  space-y-2">
                <label className="font-semibold text-sm">Sous-catégorie</label>
                <select className="select" value={values.sub_category} onChange={(e) => setValues({ ...values, sub_category: e.target.value })}>
                  <option value="">Aucune</option>
                  {SUB_CATEGORIES[values.category].map((e, index) => (
                    <option key={index} value={e.key}>
                      {e.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <label className="text-sm font-semibold w-16">Startup</label>
              <input type="checkbox" className="input" checked={values.startup} onChange={(e) => setValues({ ...values, startup: e.target.checked })} />
            </div>
            <div className="flex items-center gap-2">
              <label className="text-sm font-semibold w-16">TPE/PME</label>
              <div>
                <input type="checkbox" className="input" checked={values.sme} onChange={(e) => setValues({ ...values, sme: e.target.checked })} />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex-1 space-y-2">
              <label className="font-semibold text-sm">Type de question</label>
              <select className="select" value={values.type} onChange={(e) => setValues({ ...values, type: e.target.value })}>
                <option value="text">Texte</option>
                <option value="select">Sélection</option>
                <option value="multi-select">Sélection multiple</option>
                <option value="multi-question">Question multiple</option>
                <option value="number">Nombre</option>
                <option value="boolean">Booléen</option>
              </select>
            </div>
            <div className="flex-1 space-y-2">
              <label className="font-semibold text-sm">Clé</label>
              <input type="text" placeholder="Clé" className="input" value={values.key} onChange={(e) => setValues({ ...values, key: e.target.value })} />
            </div>
          </div>
        </div>

        {(values.type === "multi-select" || values.type === "select") && (
          <div className="mt-4">
            <div className="flex gap-4 mb-2">
              <label className="flex-1 font-semibold text-sm">Options (fr)</label>
              <label className="flex-1 font-semibold text-sm">Options (en)</label>
            </div>
            {values.options.map((option, index) => (
              <div key={index} className="flex gap-4 mb-2">
                <input className="flex-1 input" value={option.option_fr} onChange={(e) => handleOptionChange(index, "option_fr", e.target.value)} />
                <input className="flex-1 input" value={option.option_en} onChange={(e) => handleOptionChange(index, "option_en", e.target.value)} />
              </div>
            ))}
            <div className="flex justify-center mt-4">
              <button className="primary-button" onClick={handleAddOption}>
                Ajouter une règle
              </button>
            </div>
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button className="primary-button w-56" onClick={handleSubmit}>
            Ajouter
          </button>
        </div>
      </Modal>
    </>
  );
};

const EditModal = ({ isOpen, onClose, data, setData }) => {
  const [values, setValues] = useState(data);

  useEffect(() => {
    setValues(data);
  }, [data]);

  const handleSubmit = async () => {
    try {
      const res = await api.put(`/question/${data._id}`, values);
      if (res.ok) {
        setData(res.data);
        toast.success("Règle modifiée");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddOption = () => {
    setValues({ ...values, options: [...values.options, { option_fr: "", option_en: "" }] });
  };
  const handleOptionChange = (index, key, value) => {
    setValues({ ...values, options: values.options.map((r, i) => (i === index ? { ...r, [key]: value } : r)) });
  };

  if (!values) return null;

  return (
    <Modal show={isOpen} onClose={onClose}>
      <div className="px-12 h-full overflow-auto">
        <h2 className="text-xl font-semibold mb-4">Modifier une règle de notation</h2>

        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <label className="font-semibold text-sm">Question (fr)</label>
            <input type="text" placeholder="Question (fr)" className="input" value={values.question_fr} onChange={(e) => setValues({ ...values, question_fr: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label className="font-semibold text-sm">Question (en)</label>
            <input type="text" placeholder="Question (en)" className="input" value={values.question_en} onChange={(e) => setValues({ ...values, question_en: e.target.value })} />
          </div>
          <div className="flex items-center gap-4">
            <div className="flex-1  space-y-2">
              <label className="font-semibold text-sm">Catégorie</label>
              <select className="select" value={values.category} onChange={(e) => setValues({ ...values, category: e.target.value })}>
                <option value="">Aucune</option>
                {CATEGORIES.map((e, index) => (
                  <option key={index} value={e.key}>
                    {e.label}
                  </option>
                ))}
              </select>
            </div>
            {values.category && (
              <div className="flex-1  space-y-2">
                <label className="font-semibold text-sm">Sous-catégorie</label>
                <select className="select" value={values.sub_category} onChange={(e) => setValues({ ...values, sub_category: e.target.value })}>
                  <option value="">Aucune</option>
                  {SUB_CATEGORIES[values.category].map((e, index) => (
                    <option key={index} value={e.key}>
                      {e.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <label className="text-sm font-semibold w-16">Startup</label>
              <input type="checkbox" className="input" checked={values.startup} onChange={(e) => setValues({ ...values, startup: e.target.checked })} />
            </div>
            <div className="flex items-center gap-2">
              <label className="text-sm font-semibold w-16">TPE/PME</label>
              <div>
                <input type="checkbox" className="input" checked={values.sme} onChange={(e) => setValues({ ...values, sme: e.target.checked })} />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex-1 space-y-2">
              <label className="font-semibold text-sm">Type de question</label>

              <select className="select" value={values.type} onChange={(e) => setValues({ ...values, type: e.target.value })}>
                <option value="text">Texte</option>
                <option value="select">Sélection</option>
                <option value="multi-select">Sélection multiple</option>
                <option value="multi-select">Question multiple</option>
                <option value="number">Nombre</option>
                <option value="boolean">Booléen</option>
              </select>
            </div>
            <div className="flex-1 space-y-2">
              <label className="font-semibold text-sm">Clé</label>
              <input type="text" placeholder="Clé" className="input" value={values.key} onChange={(e) => setValues({ ...values, key: e.target.value })} />
            </div>
          </div>
        </div>

        {(values.type === "multi-select" || values.type === "select") && (
          <div className="mt-4">
            <div className="flex gap-4 mb-2">
              <label className="flex-1 font-semibold text-sm">Options (fr)</label>
              <label className="flex-1 font-semibold text-sm">Options (en)</label>
            </div>
            {values.options.map((option, index) => (
              <div key={index} className="flex gap-4 mb-2">
                <input className="flex-1 input" value={option.option_fr} onChange={(e) => handleOptionChange(index, "option_fr", e.target.value)} />
                <input className="flex-1 input" value={option.option_en} onChange={(e) => handleOptionChange(index, "option_en", e.target.value)} />
                <button className="empty-button" onClick={() => setValues({ ...values, options: values.options.filter((r, i) => i !== index) })}>
                  <HiOutlineTrash className="w-5" />
                </button>
              </div>
            ))}
            <div className="flex justify-center mt-4">
              <button className="primary-button" onClick={handleAddOption}>
                Ajouter une règle
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-end mt-4">
          <button className="primary-button w-56" onClick={handleSubmit}>
            Modifier
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Question;
