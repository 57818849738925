import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination";

const INVESTOR_TYPE = {
  buyer: <p className="text-primary text-xs border border-primary p-1 rounded text-center">Acquéreur</p>,
  investor: <p className="text-yellow-600 text-xs border border-yellow-600  p-1 rounded text-center">Investisseur</p>,
};

const ANNONCE_STATUS = {
  DRAFT: <p className="text-gray-500 text-center">En cours de création</p>,
  PENDING: <p className="text-yellow-500 text-center">En attente</p>,
  ACCEPTED: <p className="text-green-500 text-center">Validé</p>,
  REJECTED: <p className="text-red-500 text-center">Refusé</p>,
};

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    searchTerm: searchParams.get("searchTerm") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 51,
  });

  const [data, setData] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {};
        if (filters.status) query.annonce_status = filters.status;
        if (filters.annonce_type) query.annonce_type = filters.annonce_type;
        if (filters.searchTerm) {
          if (filters.annonce_type === "buyer") query.buyer_title = filters.searchTerm;
          else if (filters.annonce_type === "investor") query.structure_name = filters.searchTerm;
          else {
            query.structure_name = filters.searchTerm;
            query.buyer_title = filters.searchTerm;
          }
        }
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;

        setSearchParams(query);

        const res = await api.post("/investor/search", query);
        if (res.ok) {
          setData(res.data);
          setTotal(res.total);
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [filters]);

  return (
    <div className="p-8 space-y-3">
      <CreateModal show={showCreateModal} onClose={() => setShowCreateModal(false)} />
      <div className="flex justify-between items-center mt-8">
        <div className="flex gap-4">
          <select className="select" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
            <option value="">Tous</option>
            <option value="DRAFT">En cours de création</option>
            <option value="PENDING">En attente</option>
            <option value="ACCEPTED">Validé</option>
            <option value="REJECTED">Refusé</option>
          </select>
          <select className="select" value={filters.annonce_type} onChange={(e) => setFilters({ ...filters, annonce_type: e.target.value })}>
            <option value="">Tous</option>
            <option value="investor">Investisseurs</option>
            <option value="buyer">Acquéreurs</option>
          </select>
        </div>
        <button onClick={() => setShowCreateModal(true)} className="primary-button">
          Créer un investisseur/acquéreur
        </button>
      </div>
      <input
        type="text"
        placeholder="Rechercher un investisseur/acquéreur"
        className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.searchTerm}
        onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, searchTerm: event.target.value }))}
      />
      <div className="mt-15">
        <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
      </div>
      <section className="w-full p-8 flex flex-col">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col gap-5">
            <h2 className="text-xl text-primary font-semibold">Aquéreurs/Investisseurs</h2>
            <div className="flex justify-between items-center mb-5 px-8">
              <h3 className="w-10" />
              <h3 className="w-1/6 text-sm font-medium">Type</h3>
              <h3 className="flex-1 text-sm font-medium">Nom de l'annonce</h3>
              <h3 className="w-1/6 text-sm font-medium">Date de création</h3>
              <h3 className="w-1/4 text-sm font-medium text-center">Statut</h3>
            </div>
            <div className="flex flex-col gap-3">
              {data.map((e, index) => (
                <Link
                  to={`/investisseur/${e._id}`}
                  key={index}
                  onClick={() => sessionStorage.setItem("filterParams", searchParams.toString())}
                  className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center py-4 px-6">
                  <h3 className="w-10" />
                  <div className="w-1/6 flex justify-start">{INVESTOR_TYPE[e.annonce_type]}</div>
                  <div className="flex-1 items-center flex">
                    <h1 className="text-sm font-semibold ml-5">{e.annonce_type === "buyer" ? e.buyer_title : e.structure_name}</h1>
                  </div>
                  <div className="w-1/6">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
                  <div className="w-1/4 flex items-center justify-center gap-4">{ANNONCE_STATUS[e.annonce_status]}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

const CreateModal = ({ show, onClose }) => {
  const [values, setValues] = useState({
    structure_name: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await api.post("/investor", values);
      if (res.ok) {
        onClose();
        navigate(`/investisseur/${res.data._id}`);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="p-8 space-y-6">
        <h2 className="text-2xl font-semibold">Créer un investisseur</h2>
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <label htmlFor="type">Type</label>
            <select className="select w-fit" value={values.annonce_type} onChange={(e) => setValues({ ...values, annonce_type: e.target.value })}>
              <option value="buyer">Acquéreur</option>
              <option value="investor">Investisseur</option>
            </select>
          </div>
          {values.annonce_type === "buyer" ? (
            <div className="flex flex-col gap-2">
              <label htmlFor="buyer_title">Titre de l'annonce d'acquisition</label>
              <input className="input" placeholder="Titre" value={values.buyer_title} onChange={(e) => setValues({ ...values, buyer_title: e.target.value })} />
            </div>
          ) : values.annonce_type === "investor" ? (
            <div className="flex flex-col gap-2">
              <label htmlFor="structure_name">Nom de la structure d'investissement</label>
              <input className="input" placeholder="Nom de la structure" value={values.structure_name} onChange={(e) => setValues({ ...values, structure_name: e.target.value })} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex justify-end">
          <button onClick={handleSubmit} className="primary-button" disabled={loading}>
            {loading ? "Chargement..." : "Créer"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default List;
