import React, { useState } from "react";
import { toast } from "react-hot-toast";

import useStore from "../../services/store";
import Loader from "../../components/Loader";
import api from "../../services/api";

const Account = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [values, setValues] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.put(`/user`, values);
      if (!res.ok) throw new Error(res);
      setUser(res.data);
      toast.success("Updated successfully");
    } catch (error) {
      console.error("error", error);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Mon profil</h1>
        <p className="text-base text-gray-500">Gérez vos informations personnelles et professionnelles.</p>
      </header>
      <form onSubmit={handleSubmit}>
        <div className="my-10">
          <h2 className="text-xl font-semibold">Mes Informations</h2>
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-8">
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="first_name">
              Prénom
            </label>
            <input
              type="text"
              name="first_name"
              className="input"
              value={values.first_name}
              onChange={(e) => setValues({ ...values, first_name: e.target.value })}
              placeholder="Entrez le nom de votre association"
            />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="last_name">
              Nom
            </label>
            <input
              type="text"
              name="last_name"
              className="input"
              value={values.last_name}
              onChange={(e) => setValues({ ...values, last_name: e.target.value })}
              placeholder="Entrez le nom de votre association"
            />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="email">
              Adresse e-mail
            </label>
            <input
              type="email"
              name="email"
              className="input"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              placeholder="Entrez votre adresse e-mail"
            />
          </div>
          <div className="space-y-2">
            <label className="text-base font-medium" htmlFor="phone">
              Numéro de téléphone
            </label>
            <input
              type="number"
              name="phone"
              className="input"
              value={values.phone}
              onChange={(e) => setValues({ ...values, phone: e.target.value })}
              placeholder="XX XXX XXXX XXX"
            />
          </div>
        </div>

        <div className="mt-10">
          <button className="primary-button" type="submit">
            Mettre à jour le profil
          </button>
        </div>
      </form>
    </div>
  );
};

export default Account;
