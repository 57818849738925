import React, { useState } from "react";
import toast from "react-hot-toast";

import { PDF_URL } from "../../../services/config";
import Switch from "../../../components/Switch";
import api from "../../../services/api";
const Report = ({ company, setCompany }) => {
  const [editing, setEditing] = useState(false);
  const [textCompany, setTextCompany] = useState(company.pdf_current_area);
  const [textSolution, setTextSolution] = useState(company.pdf_business_model);
  const [textMarket, setTextMarket] = useState(company.pdf_target_area);
  const [timeline, setTimeline] = useState(company.pdf_timeline);

  const handlePublish = async () => {
    try {
      const res = await api.put(`/company/${company._id}`, {
        pdf_current_area: textCompany,
        pdf_business_model: textSolution,
        pdf_target_area: textMarket,
        pdf_timeline: timeline,
      });
      if (!res.ok) throw res;
      toast.success("Les informations ont été mises à jour");
      setCompany(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue lors de la mise à jour des informations");
    }
  };

  return (
    <div>
      <div className="flex items-center gap-5 p-3 mt-2">
        <h1 className="text-xl font-semibold">Prévisualisation</h1>
        <Switch checked={editing} onChange={(v) => setEditing(v)} />
        <h1 className="text-xl font-semibold">Édition</h1>
        {editing && (
          <button className="primary-button w-fit" onClick={handlePublish}>
            Enregistrer
          </button>
        )}
      </div>
      {editing ? (
        <div className="flex flex-between w-full p-2 space-x-3 rounded-t-lg">
          <div className="flex flex-col gap-5 w-[60%] m-2">
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Société</label>
              <textarea type="text" className="input" value={textCompany} onChange={(e) => setTextCompany(e.target.value)} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Solution</label>
              <textarea type="text" className="input" value={textSolution} onChange={(e) => setTextSolution(e.target.value)} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <label className="text-black text-xl">Marché</label>
              <textarea type="text" className="input" value={textMarket} onChange={(e) => setTextMarket(e.target.value)} />
            </div>
            <div className="flex flex-col justify-center gap-2">
              <div className="flex justify-between items-center">
                <label className="text-black text-xl">Timeline</label>
                <button className="primary-button" onClick={() => setTimeline([...timeline, { text: null, side: "left" }])}>
                  Ajouter
                </button>
              </div>
              {timeline.map((item, index) => (
                <div key={index} className="flex justify-between items-center gap-3">
                  <textarea
                    type="text"
                    className="input"
                    value={item.text}
                    onChange={(e) => {
                      const newTimeline = [...timeline];
                      newTimeline[index].text = e.target.value;
                      setTimeline(newTimeline);
                    }}
                  />
                  <select
                    className="select w-fit"
                    value={item.side}
                    onChange={(e) => {
                      const newTimeline = [...timeline];
                      newTimeline[index].side = e.target.value;
                      setTimeline(newTimeline);
                    }}>
                    <option value="left">{"<--"}</option>
                    <option value="right">{"-->"}</option>
                  </select>
                  <button className="empty-button" onClick={() => setTimeline(timeline.filter((_, i) => i !== index))}>
                    Supprimer
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Preview company={company} />
      )}
    </div>
  );
};

const Preview = ({ company }) => {
  return (
    <div className="w-full overflow-visible flex justify-center">
      <iframe src={`${PDF_URL}/sme/${company._id}`} className="border-2 border-black w-[1400px] h-[1684px] scale-75 origin-top-left p-0 m-0" />
    </div>
  );
};

export default Report;
