import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";
import Loader from "../../components/Loader";

const STATUS = {
  DRAFT: <span className="text-xs text-gray-500">Brouillon</span>,
  PENDING: <span className="text-xs text-yellow-500">En attente</span>,
  ACCEPTED: <span className="text-xs text-green-500">Validée</span>,
  REJECTED: <span className="text-xs text-red-500">Refusée</span>,
};
const List = () => {
  const [filters, setFilters] = useState({
    status: "",
    page: 1,
    pageSize: 50,
  });

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = { annonce: true, annonce_type: "cession" };
        if (filters.status) query.annonce_status = filters.status;
        if (filters.page) query.skip = (filters.page - 1) * filters.pageSize;
        const res = await api.post("/company/search", query);
        if (res.ok) setCompanies(res.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mt-8">
        <div className="flex gap-4">
          <select className="select" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
            <option value="">Toutes les annonces</option>
            <option value="draft">En cours de création</option>
            <option value="pending">En attente</option>
            <option value="validated">Validé</option>
            <option value="rejected">Refusé</option>
          </select>
        </div>
      </div>

      <section className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center mb-4 px-4">
          <h3 className="w-24 text-sm font-semibold">Statut</h3>
          <h3 className="flex-1 text-sm font-semibold px-4">Titre de l'annonce</h3>
          <h3 className="flex-1 text-sm font-semibold">Entreprise</h3>
          <h3 className="w-[15%] text-sm font-semibold">Créer par</h3>
          <h3 className="w-[15%] text-right text-sm font-semibold">Date de création</h3>
        </div>

        <div className="space-y-2">
          {loading ? (
            <Loader />
          ) : companies.length === 0 ? (
            <div className="text-center">Aucune annonce trouvée</div>
          ) : (
            companies.map((e, index) => (
              <Link to={`/cession/${e._id}`} key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-4">
                <div className="w-24">
                  <div>{STATUS[e.annonce_status]}</div>
                </div>
                <div className="flex-1 px-4 line-clamp-2">{e.annonce_title}</div>
                <div className="flex-1">{e.company_name}</div>
                <div className="w-[15%]">
                  {e.user_first_name} {e.user_last_name}
                </div>
                <div className="w-[15%] text-right">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
              </Link>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default List;
