import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { HiOutlineDocumentText, HiOutlineDownload } from "react-icons/hi";

import Loader from "../../../components/Loader";
import api from "../../../services/api";

const Documents = ({ company }) => {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { company_id: company._id });
        if (res.ok) {
          setDocuments(res.data);
        } else {
          toast.error("Erreur lors de la récupération des documents");
        }
      } catch (error) {
        toast.error("Erreur lors de la récupération des documents");
      }
      setLoading(false);
    };
    fetchDocuments();
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      <h1 className="text-xl font-semibold mb-8">Documents</h1>
      <div className="w-full">
        {documents.length > 0 ? (
          <>
            <div className="flex justify-between items-center mb-4">
              <h3 className="flex-1 text-sm font-semibold pl-12">Documents</h3>
              <h3 className="w-1/4 text-sm font-semibold text-center">Date d'ajout</h3>
              <h3 className="w-20 text-sm font-semibold text-center">Voir</h3>
            </div>
            <div className="divide-y divide-secondary">
              {documents.map((item, index) => (
                <div key={index} className="flex justify-between items-center py-2">
                  <div className="flex-1 items-center flex">
                    <HiOutlineDocumentText className="w-6 h-6 mr-6 text-primary" />
                    <p className="font-medium">{item.file_name}</p>
                  </div>
                  <div className="w-1/4 text-center">{new Date(item.created_at).toLocaleDateString("fr-FR")}</div>
                  <div className="w-20 flex justify-center">
                    <a href={item.file_url} target="_blank" rel="noreferrer">
                      <HiOutlineDownload className="w-6 h-6 text-primary" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center h-40">
            <p className="text-sm text-gray-400">Aucun document</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Documents;
