import React, { useEffect, useState } from "react";
import { LuPlus, LuMinus } from "react-icons/lu";
import { toast } from "react-hot-toast";

import api from "../../../services/api";
import { notation, executeNotation, calculateWeightedScore, calculatePotentialScore, calculateRiskScore, getScoreLetter, getWeights } from "../../../valuations/notation";

const DOMAINS = {
  market_size_coef: "Market size",
  market_share_coef: "Market share",
  competitive_advantage_coef: "Competitive advantage",
  disruptive_tech_coef: "Disruptive technology",
  key_access_coef: "Key access",
  growth_strategy_coef: "Growth strategy",
  team_skills_coef: "Team skills",
  product_validation_coef: "Product validation",
  tech_doability_coef: "Tech doability",
  tech_law_protection_coef: "Tech law protection",
  financial_strength_coef: "Financial strength",
  strategy_backing_coef: "Strategy backing",
  potential_impact_coef: "Potential impact",
  social_responsability_coef: "Social responsability",
};

const Notation = ({ company, setCompany }) => {
  const [filters, setFilters] = useState({
    search: "",
  });

  const [notationRules, setNotationRules] = useState([]);

  const scores = notation(company, notationRules);
  const weights = getWeights(company.startup_state);
  const weightedScore = calculateWeightedScore(scores, weights);
  const potentialScore = calculatePotentialScore(scores, weights);
  const riskScore = calculateRiskScore(scores, weights);
  const scoreLetter = getScoreLetter(weightedScore);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post("/notation-rules/search", { limit: 300 });
        if (res.ok) {
          setNotationRules(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    if (!weightedScore || !potentialScore || !riskScore || !scoreLetter) return;
    const pushData = async () => {
      try {
        const res = await api.put(`/company/${company._id}`, {
          notation_total_score: weightedScore,
          notation_potential_score: potentialScore,
          notation_risk_score: riskScore,
          notation_letter_score: scoreLetter,
        });
        if (!res.ok) throw res;
        toast.success("Les informations ont été mises à jour");
        setCompany(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue lors de la mise à jour des informations");
      }
    };
    pushData();
  }, [weightedScore, potentialScore, riskScore, scoreLetter]);

  return (
    <div className="space-y-6">
      <h1 className="text-xl font-semibold mb-4">Notation</h1>

      <div className="mt-4 p-4 bg-gray-100 rounded flex justify-between items-center">
        <h4 className="text-lg font-semibold">Potentiel: {potentialScore}/10</h4>
        <h4 className="text-lg font-semibold">
          Score global: {weightedScore}/10 - {scoreLetter}
        </h4>
        <h4 className="text-lg font-semibold"></h4>
        <h4 className="text-lg font-semibold">Risque: {10 - riskScore}/10</h4>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {Object.entries(DOMAINS).map(([key, value], i) => (
          <div key={key} className="p-3 flex items-center flex-col justify-center rounded col-span-1 text-center border border-gray-300">
            <label>{value}</label>
            <p className="text-lg font-semibold">
              {(Number(scores[key.split("_coef")[0]]) * 10) % 1 === 0 ? Number(scores[key.split("_coef")[0]]) : Number(scores[key.split("_coef")[0]]).toFixed(2)}
              /10
            </p>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between">
        <div className="space-y-2">
          <label className="text-lg font-semibold">Rechercher</label>
          <input type="text" value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="input" />
        </div>
      </div>
      <section className="w-full flex py-4 flex-col">
        <div className="flex justify-between items-center mb-4">
          <h3 className="flex-1 text-sm font-semibold">Critère</h3>
          <h3 className="w-[20%] text-sm font-semibold text-center"></h3>
        </div>
        {notationRules
          .filter((rule) => rule.name.toLowerCase().includes(filters.search.toLowerCase()))
          .map((rule, i) => (
            <Rule key={i} rule={rule} company={company} />
          ))}
      </section>
    </div>
  );
};

const Rule = ({ rule, company }) => {
  const [open, setOpen] = useState(true);

  const notation = executeNotation(company, rule);

  const answersWithValues = rule.answers.map((answer) => {
    let value = "Non défini";

    if (company) {
      // Vérifiez si la clé est dans l'objet `company`
      if (company.hasOwnProperty(answer.question_key)) {
        value = company[answer.question_key];
      } else if (answer.question_key.includes(".")) {
        // Si la clé contient un point, vérifiez les sous-objets
        const keys = answer.question_key.split(".");
        if (keys.length === 2 && Array.isArray(company[keys[0]])) {
          // Parcourez le tableau pour trouver la valeur
          for (const item of company[keys[0]]) {
            if (item.hasOwnProperty(keys[1])) {
              value = item[keys[1]];
              break;
            }
          }
        }
      }
    }

    const valueStr = value !== null && value !== undefined ? value.toString() : "Non défini";
    return {
      ...answer,
      value: valueStr,
    };
  });

  const uniqueAnswers = answersWithValues.reduce((acc, answer) => {
    if (!acc.some((item) => item.question === answer.question)) {
      acc.push(answer);
    }
    return acc;
  }, []);

  const excludeKeys = ["points", "total_points", "v"];

  return (
    <div className={`mb-2 border border-secondary hover:border-black p-3 transition-all rounded-lg ${open && "border-black"}`}>
      <button onClick={() => setOpen(!open)} className="mb-1 flex justify-between items-center w-full">
        <h2 className="text-l font-semibold">{rule.name}</h2>
        {open ? <LuMinus className="text-black" /> : <LuPlus className="text-black" />}
      </button>
      <div className={`space-y-2 overflow-hidden transition-all duration-300 ease-in-out text-sm ${open ? "opacity-100 p-4 " : "h-0 opacity-0 p-0"}`}>
        {uniqueAnswers.map((answer, i) => (
          <div key={i} className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <label className="text-black text-sm">{answer.question}</label>
              <p className="font-semibold text-slate-600 ">{answer.value}</p>
            </div>
            {notation.points !== null ? <div className="font-semibold">Score : {notation.points}/10</div> : <div className="font-semibold">Non considéré</div>}
          </div>
        ))}
        {Object.entries(notation)
          .filter(([key, value]) => !excludeKeys.includes(key) && typeof value === "number" && value > 0)
          .map(([key, value]) => (
            <div key={key} className="flex justify-between items-center gap-3">
              <div className="w-[20%] text-left pr-4">{key.replace(/_/g, " ")}</div>
              <div className="flex-1 line-clamp-2 font-semibold text-left">{value}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Notation;
