import React, { useEffect, useState } from "react";

const DebounceInput = ({ debounce, ...props }) => {
  const [input, setInput] = useState(props.value);

  useEffect(() => {
    const handler = setTimeout(() => {
      props.onChange(input);
    }, debounce);
    return () => clearTimeout(handler);
  }, [input]);

  return <input {...props} value={input} onChange={(e) => setInput(e.target.value)} />;
};

export default DebounceInput;
