import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";

import api from "../../services/api";
import { API_URL } from "../../services/config";
import Pagination from "../../components/Pagination";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    search: searchParams.get("search") || "",
    page: Number(searchParams.get("page")) || 1,
    limit: Number(searchParams.get("limit")) || 100,
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = {};
        // if (filters.status) query.annonce_status = filters.status;
        if (filters.search) query.search = filters.search;
        if (filters.page) query.skip = (filters.page - 1) * filters.limit;
        if (filters.limit) query.limit = filters.limit;

        setSearchParams(filters);

        const res = await api.post("/admin/search", query);
        if (!res.ok) throw res;
        setUsers(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Erreur lors de la récupération des données");
      }
      setLoading(false);
    };

    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <header className="w-full">
        <h1 className="text-3xl font-semibold text-black mb-2">Utilisateurs</h1>
        <p className="text-base text-gray-500">Voici la liste des utilisateurs de Linkera</p>
      </header>
      <input
        type="text"
        placeholder="Rechercher un utilisateur"
        className="mt-3 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        value={filters.search}
        onChange={(event) => setFilters((prevFilters) => ({ ...prevFilters, search: event.target.value }))}
      />
      {loading ? <div className="flex justify-center items-center my-10">
        <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
        <p className="text-primary">Chargement...</p>
      </div> :
        <section className="w-full p-8 flex flex-col">
          <div className="flex justify-between items-center mb-4 pl-4 pr-28">
            <h3 className="flex-1 text-sm font-semibold">Nom</h3>
            <h3 className="flex-1 text-sm font-semibold">E-mail</h3>
            <h3 className="w-[15%] text-sm font-semibold">Inscrit le</h3>
            <h3 className="w-[15%] text-sm font-semibold">Dernière connexion</h3>
          </div>

          <div className="space-y-2">
            {users.map((e, index) => (
              <div key={index} className="flex justify-between items-center gap-3">
                <Link to={`/user/${e._id}`} className="flex-1 border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-4">
                  <div className="flex-1">
                    {e.first_name} {e.last_name}
                  </div>
                  <div className="flex-1">{e.email}</div>
                  <div className="w-[15%]">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
                  <div className="w-[15%]">{new Date(e.last_login_at).toLocaleDateString("fr-FR")}</div>
                </Link>
                <a href={`${API_URL}/admin/loginas/${e._id}`} target="_blank" className="primary-button py-4">
                  Login as
                </a>
              </div>
            ))}
          </div>
        </section>
      }
      <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.limit)} />
    </div>
  );
};

export default Home;
